<template>
<div style="height: 28rem">
    <LoadingDataError v-if="loadingError" :ErrorCode="errorCode" />

    <div class="p-grid" v-if="!loadingError" style="align-items: center;">
        <div class="p-col-12">
            <div style="text-align: right">
                <Button v-if="itineraryData.length > 0" label="Print Itinerary" @click="openPrintDialog()" class="p-mb-1 p-button-secondary p-button-sm" icon="pi pi-print" iconPos="left" />
            </div>
        </div>
    </div>
    <ScrollPanel v-if="!loadingError" style="width: 100%; height: 23.5rem" class="custom-scrollbar">
    <Dialog v-model:visible="printDialog" :style="{width: '22cm'}" :modal="true">
        <template #header>
            <h5 style="margin: 0px">Print Itinerary</h5>
        </template>

    </Dialog>

    <div v-for="(itinerary, index) of itineraryData" :key="index"> 
        <div style="color: #0071cb; font-weight: bold; text-transform: uppercase;">
            <i class="pi pi-calendar p-mr-1"></i>
            {{itinerary.date + ': ' + itinerary.itin_outline}}
        </div>
        <div class="itineray-detail" style="margin-bottom: 1.5rem">
            <span v-html="itinerary.itin_detail"></span>
        </div>
        <Divider />
    </div>
    <div v-if="itineraryData.length > 0" class="p-grid">
        <div class="p-field p-col-6" style="margin-bottom: 0">
            <label style="font-weight: 500">Inclusive of:</label>
            <span class="p-mt-3" v-html="itineraryInclusive.inclusive"></span>
        </div>
        <div class="p-field p-col-6" style="margin-bottom: 0">
            <label style="font-weight: 500">Exclusive of:</label>
            <span v-html="itineraryInclusive.exclusive"></span>
        </div>
    </div>

    </ScrollPanel>
</div>
</template>

<script>
import { ref, reactive } from 'vue'

import LoadingDataError from '../components/LoadingDataError.vue'
import BookingService from '../service/BookingService'

export default {
    
    setup() {
        let loadingError = ref(false)
        let errorCode = ref('')
        let itineraryData = ref([])
        let itineraryInclusive = reactive({})
        let printDialog = ref(false)
        let booking_ID = ref(0)

        const bookingService = ref(new BookingService())

        function getBookingItinerary(BookingID) {
            booking_ID.value = BookingID

            if(booking_ID.value != 0) {
                bookingService.value.getItineraryData(booking_ID.value).then(data => {
                    if(!data.errorResponse) {
                        itineraryData.value = data.itineraryData
                        Object.assign(itineraryInclusive, data.itinInclusive[0])
                        loadingError.value = false
                    } else {
                        loadingError.value = true
                        errorCode.value = data.status
                    }
                })
            }
        }

        const openPrintDialog = () => {
            window.open('/itinerary/print-itinerary/?bookingid='+booking_ID.value, 'popUpWindow','height=900,width=720,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes')
        }

        return {
            loadingError,
            itineraryData,
            errorCode,
            itineraryInclusive,
            getBookingItinerary,

            printDialog,
            openPrintDialog,
        }
    }, 
    components: {
        LoadingDataError,
    }
}
</script>

<style lang="scss" scoped>

</style>